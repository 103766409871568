<template>
	<simple-loading
		:is-loading="isLoading"
		:is-load-failed="loadFail"
		:showLoadingCard="true"
		@reload="fetchDetail"
	>
		<div class="card p-2">
			<el-form v-loading="saveLoading">
				<el-descriptions
					direction="vertical"
					class="margin-top"
					title="Account Details"
					:column="3"
					border
				>
					<template slot="extra">
						<el-button
							type="primary"
							size="small"
							@click="saveDetail"
							>Save</el-button
						>
					</template>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-user"></i>
							Username (Same as E-mail)
						</template>

						<div class="center">
							<avatar
								:username="detail.email"
								:size="24"
							></avatar>
							{{ detail.email }}
						</div>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-user"></i>
							Full Name*
							<span
								v-show="showMissingFullName"
								style="color: red"
								>(Required)</span
							>
						</template>
						<el-input
							required
							v-model="detail.fullName"
							placeholder="Please input fullname"
							:validate-event="true"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-message"></i>
							E-mail*
							<span v-show="showMissingEmail" style="color: red"
								>(Required)</span
							>
						</template>
						<el-input
							required
							type="email"
							v-model="detail.email"
							placeholder="Please input email"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-position"></i>
							Phone Area Code
						</template>
						<el-input
							v-model="detail.phoneAreaCode"
							placeholder="Please input phone area code"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-mobile-phone"></i>
							Phone Number
						</template>
						<el-input
							v-model="detail.phone"
							placeholder="Please input phone number"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-office-building"></i>
							Company
						</template>
						<el-input
							v-model="detail.company"
							placeholder="Please input company name"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-table-lamp"></i>
							Department
						</template>
						<el-input
							v-model="detail.department"
							placeholder="Please input department"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item span="2">
						<template slot="label">
							<i class="el-icon-view"></i>
							Account Enable
						</template>
						<el-switch
							v-model="detail.enable"
							active-text="Active"
							inactive-text="Inactive"
						>
						</el-switch>
					</el-descriptions-item>

					<!-- <el-descriptions-item>
						<template slot="label">
							<i class="el-icon-lock"></i>
							Account Locked
						</template>
						<el-switch
							v-model="detail.locked"
							active-text="Unlock"
							inactive-text="Locked"
						>
						</el-switch>
					</el-descriptions-item> -->

					<el-descriptions-item :span="4">
						<template slot="label">
							<i class="el-icon-date"></i>
							Permissions
						</template>
						<el-checkbox-group v-model="detail.permissions">
							<el-checkbox
								v-for="permission in wrapperPermissions"
								:label="permission.key"
								:key="permission.key"
								>{{ permission.label }}</el-checkbox
							>
						</el-checkbox-group>
					</el-descriptions-item>
				</el-descriptions>
			</el-form>
		</div>
	</simple-loading>
</template>

<script>
	import { computed, onMounted, ref } from "@vue/composition-api";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import Avatar from "vue-avatar";
	import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";
	import store from "@/store";

	export default {
		components: {
			Avatar,
			SimpleLoading,
		},
		setup(props, ctx) {
			const isLoading = ref(false);
			const loadFail = ref(false);
			const detail = ref({
				username: null,
				fullName: null,
				email: "",
				phone: null,
				phoneAreaCode: null,
				company: null,
				department: null,
				isEnable: false,
				isLocked: false,
				permissions: [],
			});
			const permissions = ref([]);

			const saveLoading = ref(false);
			const saveLoadFail = ref(false);

			const showMissingEmail = ref(false);
			const showMissingFullName = ref(false);

			onMounted(() => {
				fetchDetail();
			});

			const fetchDetail = async () => {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const permissionsRes = await fetcher(
						apis.permissionList,
						FetchMethod.GET
					);
					permissions.value = permissionsRes;
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			};

			const wrapperPermissions = computed(() => {
				return permissions.value.map((permission) => {
					return {
						key: permission.id,
						label: permission.displayName,
					};
				});
			});

			const saveDetail = async () => {
				if (
					detail.value.fullName == null ||
					detail.value.fullName.trim().length == 0
				) {
					showMissingFullName.value = true;
					return;
				} else {
					showMissingFullName.value = false;
				}

				if (
					detail.value.email == null ||
					detail.value.email.trim().length == 0
				) {
					showMissingEmail.value = true;
					return;
				} else {
					showMissingEmail.value = false;
				}

				saveLoading.value = true;
				saveLoadFail.value = false;

				try {
					const response = await fetcher(
						apis.createAccountDetail,
						FetchMethod.POST,
						detail.value
					);

					ctx.root.$router.push({
						name: "setting-account-detail",
						params: {
							userId: response.id,
						},
					});
				} catch (e) {
					saveLoadFail.value = true;

					if (e.code == -22) {
						store.commit("app/SHOW_ERROR_DIALOG", {
							isOpen: true,
							title: e.message,
							message: e.detail,
						});
					} else {
						store.commit("app/SHOW_ERROR_DIALOG", {
							isOpen: true,
							message: e,
						});
					}
				} finally {
					saveLoading.value = false;
				}
			};

			return {
				isLoading,
				loadFail,
				detail,
				wrapperPermissions,

				fetchDetail,
				saveDetail,

				saveLoading,
				saveLoadFail,

				showMissingEmail,
				showMissingFullName,
			};
		},
	};
</script>


<style scoped>
	.center {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	.permission-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>